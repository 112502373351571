
body {
  margin: 0;
  padding: 0;
}

*, *::after,*::before {
  box-sizing: border-box;
}

h2 {
  margin: 0px;
  text-align: center;
}
.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 0px;
  padding-right: 10px;
}


.btn-wrapper button {
  background: #0bb2d7;
  line-height: 32px;
  padding: 0 20px;
  border: none;
  margin-right: 10px;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#docpicker, #cke_notifications_area_editor1, .cke_copyformatting_notification, .cke_notifications_area {
  display: none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.btn-wrapper input {
  background: #f5eeee;
  line-height: 32px;
  padding: 0 15px;
  cursor: pointer;
}

.btn-wrapper .save-doc {
  background-color: #5ba511;
}

.ck.ck-editor {
  padding: 0 20px !important;
}


.main-wrapper {
  padding: 0 20px;
}

/* file folder popup */

.fm-popup {
  position: relative;
  width: 100%;
  height: calc(99vh - 80px);
  overflow: hidden;
  z-index: 9999;
  display: flex;
  background: #f5eeee;
}

/* .fm-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.1);
  z-index: 1;
} */


.fm-popup > .ant-layout {
  position: absolute;
  width: 90%;
  height: calc(85% - 80px);
  top: 0;
  left: 0;
  transform: translate(5%, 5%);
  border: 1px solid #000;
  z-index: 2;
  background-color: #fff;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}

.ant-layout-content {
  flex: auto;
}

.ant-layout-footer {
  display: none;
}
.bpoLin, .ygJYN {
  align-items: center;
  display: flex;
}

.folder-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder-icon {
  margin-right: 5px;
  flex: 1 1 0%;
}
.folder-text {
  flex: 5 1 0%;
}

.folder-arrow {
  flex: 1 1 0%;
}
.folder-arrow svg {
  width: 10px;
}

.kHPhUT {
  border-bottom: 1px solid #ccc;
}

/* .fm-popup > .ant-layout aside .ant-layout-sider-children > div {
  margin: 0 !important;
} */

.fm-popup > .ant-layout aside .ant-layout-sider-children > div input {
  max-width: 100%;
  padding: 5px 0;
  color: #5ba511;
}



.ant-layout {
  display: flex;
  flex-direction: column;
  flex: auto;
  background: #f0f2f5;
}

.ant-layout-sider {
  transition: all .2s;
  position: relative;
}

.fm-popup > .ant-layout > .ant-layout-has-sider {
  display: flex;
  flex-direction: row;
}


.ant-layout-sider-children {
  height: 100%;
  padding-top: 0.1px;
  margin-top: -0.1px;
}


#cke_1_contents, .cke_contents {
  height: 600px !important;
}


.fm-popup-close {
  position: absolute;
  right: 5%;
  top: 12px;
  background: #ff2500;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 111;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ant-btn-group button {
  margin: 0 4px;
  height: 32px;
  width: 32px;
}

.ant-btn-group button i {
  font-size: 16px;
}




.ant-tooltip {
  font-family: "Helvetica Neue For Number","Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 9999;
  display: block;
  visibility: visible;
}


.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 3px;
  border-width: 5px 5px 0;
  border-top-color: rgba(0, 0, 0, 0.75);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  margin-left: -5px;
}

.ant-tooltip-inner {
  max-width: 250px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-height: 32px;
}


.ant-tooltip-hidden {
  display: none ;
}

.save-btn {
  margin: 3px 0 5px;
  border: none;
  line-height: 24px;
  background: #5ba511;
  color: #fff;
  padding: 0 10px;
  border-radius: 2px;
  cursor: pointer;
}